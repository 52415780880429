// axios
import axios from 'axios';
import ls from 'local-storage'
import router from '@/router'


let baseURL = process.env.VUE_APP_API_URL;


const instance = axios.create({
  baseURL,
  //withCredentials: true
});

instance.interceptors.request.use(config => {
  if (ls.get('t')) {
    config.headers.common.Authorization = `Bearer ${ls.get('t')}`
    config.headers.post['Content-Type'] = 'application/json'
  }

  return config;
});

instance.interceptors.response.use( response => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    ls.clear()
    router.push('/login')
  }
  return Promise.reject(error)
})

export default instance;