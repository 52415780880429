import Vue from 'vue';
import router from '../router';
import store from '../store';
import { format, addHours, getHours, getMinutes } from 'date-fns';
import AWS from 'aws-sdk';

Vue.mixin({
  methods: {
    isAdminOrSuperuser() {
      let verify = false;
      const isSuperUser = store.getters.user.is_superuser;
      const role = store.getters.company.role;
      if (role.slug === 'admin' || isSuperUser) {
        verify = true;
      } else {
        router.push({ name: 'page-error-404' });
      }
      return verify;
    },
    isAdmin() {
      let verify = false;
      const role = store.getters.company.role;
      if (role && role.slug === 'admin') {
        verify = true;
      } else {
        router.push({ name: 'page-error-404' });
      }
      return verify;
    },
    isSuperUser() {
      let verify = false;
      const isSuperUser = store.getters.user.is_superuser;
      if (isSuperUser) {
        verify = true;
      } else {
        router.push({ name: 'page-error-404' });
      }
      return verify;
    },
    dateFormatter(date, format) {
      if (!format) format = 'DD/MM/YYYY HH:mm:ss';
      if (date) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.momentTimeZone(date).tz(tz).format(format);
      } else {
        return ' - ';
      }
    },
    currencyFormatter(currency) {
      return (currency / 100).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    percentageFormatter(data) {
      try {
        return data.toString().replace('.', ',') + '%';
      } catch (err) {
        return '';
      }
    },

    subString(text, size) {
      if (text.length > size) return text.substr(0, size) + '...';
      else return text;
    },

    maskCpf(valor) {
      if (valor && valor.length === 11)
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
      else return this.$t('errorMenssage.cpfInvalid');
    },

    mascaraCnpj(valor) {
      try {
        return valor.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          '$1.$2.$3/$4-$5'
        );
      } catch (err) {
        return '';
      }
    },

    mascaraPhone(data) {
      var countString = data;
      if (countString.length == 11) {
        const parte1 = data.slice(0, 2);
        const parte2 = data.slice(2, 3);
        const parte3 = data.slice(3, 7);
        const parte4 = data.slice(7, 11);
        return (data = ` (${parte1}) ${parte2} ${parte3}-${parte4}`);
      } else {
        const parte1 = data.slice(0, 2);
        const parte2 = data.slice(2, 6);
        const parte3 = data.slice(6, 10);
        return (data = ` (${parte1}) ${parte2}-${parte3}`);
      }
    },
    mascaraPhone02(data) {
      const parte1 = data.slice(0, 2);
      const parte2 = data.slice(2, 6);
      const parte3 = data.slice(6, 10);
      return (data = `${parte2}-${parte3}`);
    },

    mascaraCnae(data) {
      const parte1 = data.slice(0, 4);
      const parte2 = data.slice(4, 5);
      const parte3 = data.slice(5, 7);
      return (data = `${parte1}-${parte2}/${parte3}`);
    },

    formatQueryParams(data) {
      let queryString = '';
      for (let [key, value] of Object.entries(data)) {
        if (value || value === false) {
          if (queryString === '') {
            queryString = `${queryString}?${key}=${value}`;
          } else {
            queryString = `${queryString}&${key}=${value}`;
          }
        }
      }
      return queryString;
    },

    regexNumber(value) {
      try {
        return value.replace(/[^0-9 ]/g, '');
      } catch (err) {
        return '';
      }
    },

    encodeQueryData(data) {
      const ret = [];
      for (let d in data)
        if (data[d])
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      return ret.join('&');
    },

    statusVerify(status) {
      var st = '';
      try {
        switch (status) {
          case 'FINISHED':
            st = 'Finalizada';
            break;
          case 'CANCELED':
            st = 'Cancelada';
            break;
          case 'OPEN':
            st = 'Aberta';
            break;
          case 'REOPENED':
            st = 'Reaberta';
            break;
          default:
            break;
        }

        return st;
      } catch (err) {
        return '';
      }
    },
    formatDateAndHour(val) {
      let currentDate = '-';
      if (val && new Date(val)) {
        const currentDateFormat = addHours(new Date(val), -3);
        let hours = `${getHours(currentDateFormat)}`;
        let minutes = `${getMinutes(currentDateFormat)}`;
        currentDate = format(currentDateFormat, 'dd/MM/yyyy');
        hours = hours.length === 2 ? hours : `0${hours}`;
        minutes = minutes.length === 2 ? minutes : `0${minutes}`;
        currentDate = `${currentDate} ${hours}:${minutes}`;
      }
      return currentDate;
    },
    returnUrlS3(path) {
      const credentials = {
        accessKeyId: process.env.VUE_APP_S3_KEY,
        secretAccessKey: process.env.VUE_APP_S3_SECRET,
      };

      AWS.config.update({
        credentials: credentials,
        region: process.env.VUE_APP_S3_REGION,
      });

      const s3 = new AWS.S3();

      const presignedGETURL = s3.getSignedUrl('getObject', {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: path,
        Expires: 300,
      });
      return presignedGETURL;
    },
  },
});
