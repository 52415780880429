export default {
  state: {
    messageNotification: null,
  },
  mutations: {
    SET_MESSAGE_NOTIFICATION(state, payload) {
      state.messageNotification = payload;
    },
  },
  actions: {},
  getters: {
    messageNotification: (state) => state.messageNotification,
  },
};
