import { orderServiceDao } from '@/service';

export default {
  state: {
    documents: [],
  },
  mutations: {
    SET_DOCUMENTS_ALL(state, payload) {
      state.documents = payload;
    },
  },
  actions: {
    getAllOrderService({ commit }, payload) {
      const config = {
        params: {
          company_id: payload.company_id,
          client_id: payload.client_id
        },
      };
      return new Promise((resolve, reject) => {
        orderServiceDao
          .getAllOrderService(config)
          .getPage()
          .then((resp) => {
            commit('SET_DOCUMENTS_ALL', resp.data);
            resolve(resp.data);
          })
          .catch((err) => {
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar documentos!',
              text: err.response.data,
              color: 'danger',
            });
            reject(err);
          });
      });
    },
    getOrderService({ commit }, payload) {
      return new Promise((resolve, reject) => {
        orderServiceDao
          .getOrderService(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar documento!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },
  },
  getters: {
    orderServiceAll: (state) => state.orderServiceAll,
  },
};
