import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import  AppEchartLine  from '@core/components/charts/echart/AppEchartLine.vue'


Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(AppEchartLine.name, AppEchartLine)


// v-select component
import vSelect from 'vue-select'
//import 'vue-select/dist/vue-select.css';
import '@/@core/scss/vue/libs/vue-select.scss';
// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    })
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    })
  }
})

Vue.component(vSelect)
