import { sendPdfDao } from '@/service';

export default {
  actions: {
    insertPdf({ commit, getters }, payload) {
      return new Promise((resolve, reject) => {
        sendPdfDao
          .insertPdf({ ...payload, company_id: getters.company.id })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao salvar pdf!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    getPdf({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sendPdfDao
          .getPdf(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao baixar o pdf!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    insertExternalPDF({ commit, getters }, payload) {
      return new Promise((resolve, reject) => {
        sendPdfDao
          .insertExternalPDF({ ...payload, company_id: getters.company.id })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao salvar pdf externo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },


    removeExternalPDF({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sendPdfDao
          .removeExternalPDF(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao excluir pdf externo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    getAllExternalPdfs({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sendPdfDao
          .getAllExternalPdfs(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao baixar o pdf!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

  },
};
