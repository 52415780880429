import Paginator from '../_paginator'
import http from '../api'


let getAllOrderService = (config) => {
  return new Paginator({
    url: '/order-service/',
    config: config
  });
}

let getOrderService = (id) => {
  return http.get(`order-service/${id}`)
}


export default {
  getAllOrderService,
  getOrderService,
}