import { pdfGroupDao } from '@/service';

export default {
  actions: {
    getPdfGroups({ commit, getters}) {
      return new Promise((resolve, reject) => {
        pdfGroupDao
          .getPdfGroups({ company_id: getters.company.id })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar grupos!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    savePdfGroup({ commit, getters }, payload) {
      return new Promise((resolve, reject) => {
        pdfGroupDao
          .savePdfGroup({ ...payload, company_id: getters.company.id })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao salvar grupo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    updatePdfGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        pdfGroupDao
          .updatePdfGroup({...payload})
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao atualizar o grupo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    removePdfGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        pdfGroupDao
          .removePdfGroup(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao excluir grupo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

    changeExternalPdfGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        pdfGroupDao
          .changeExternalPdfGroup(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao mudar grupo do pdf externo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },

  },
};
