import http from '../apiAuth';

let emailRecoveryPassword = (data) => {
  return http.post('infopasswordrecovery', data);
};

let verifyHashRecoveryPassword = (data) => {
  return http.post('verifyhash', data);
};

let changePassword = (data, token) => {
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  return http.post('changepassword', data, config);
};
export default {
  emailRecoveryPassword,
  verifyHashRecoveryPassword,
  changePassword,
};
