import ls from 'local-storage';
import { authDao } from '@/service';
import { clientDao } from '@/service';

export default {
  state: {
    user: null,
    company: null,
    companies: [],
    clientUser: null,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_COMPANY(state, payload) {
      state.company = payload;
    },

    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },

    UNSET_USER(state) {
      state.user = null;
    },
    SET_CLIENT_USER(state, payload) {
      state.clientUser = payload;
    },
    UNSET_CLIENT_USER(state) {
      state.clientUser = null;
    },
  },
  actions: {
    initAuth({ commit }) {
      return new Promise((resolve) => {
        if (!ls.get('t')) {
          commit('UNSET_CLIENT_USER');
          commit('UNSET_USER');
          resolve();
          return;
        } else {
          authDao
            .refreshUser()
            .then((resp) => {
              commit('SET_USER', resp.data.user);
              commit('SET_COMPANIES', resp.data.companies);
              if (resp.data.companies.length === 1) {
                commit('SET_COMPANY', resp.data.companies[0]);
                ls.set('company', resp.data.companies[0]);
                clientDao.getClientUser({company_id: resp.data.companies[0].id, user_id: resp.data.user.id}).then((resp) => {
                  commit('SET_CLIENT_USER', resp.data);
                })
              } else {
                if (ls.get('company')) {
                  commit('SET_COMPANY', ls.get('company'));
                  clientDao.getClientUser({company_id: ls.get('company').id, user_id: resp.data.user.id}).then((resp) => {
                    commit('SET_CLIENT_USER', resp.data);
                  })
                } else {
                  ls.set('company', resp.data.companies[0]);
                  clientDao.getClientUser({company_id: resp.data.companies[0].id, user_id: resp.data.user.id}).then((resp) => {
                    commit('SET_CLIENT_USER', resp.data);
                  })
                }
              }
              resolve();
            })
            .catch(() => {
              commit('UNSET_CLIENT_USER');
              commit('UNSET_USER');
              resolve();
              return;
            });
        }
      });
    },
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .login(payload)
          .then(async (resp) => {
            await ls.set('t', resp.data.token);
            authDao
              .refreshUser()
              .then((resp) => {
                commit('SET_USER', resp.data.user);
                commit('SET_COMPANIES', resp.data.companies);
                if (resp.data.companies.length === 1) {
                  commit('SET_COMPANY', resp.data.companies[0]);
                  ls.set('company', resp.data.companies[0]);
                  clientDao.getClientUser({company_id: resp.data.companies[0].id, user_id: resp.data.user.id}).then((resp) => {
                    commit('SET_CLIENT_USER', resp.data);
                  })
                }
                resolve(resp);
              })
              .catch((err) => {
                commit('UNSET_USER');
                ls.clear();
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getClientUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        clientDao
          .getClientUser(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar clientUser!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },
    logout() {
      this.commit('UNSET_USER');
      this.commit('UNSET_CLIENT_USER');
      this.commit('SET_COMPANY', null);
      ls.clear();
      // return new Promise((resolve) => {
      // resolve(true);
      // });
    },
  },
  getters: {
    isLoggedIn: (state) => Boolean(state.user),
    user: (state) => state.user,
    company: (state) => state.company,
    companies: (state) => state.companies,
    client_user: (state) => state.clientUser,
  },
};
