import http from '../apiAuth'

let login = (data) => {
  return http.post('auth', data)
}
let refreshUser = () => {
  return http.get('user')
}

export default {
  login,
  refreshUser
}
