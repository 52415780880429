<template>
  <e-charts
    class="pd"
    ref="line"
    autoresize
    :options="line"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/line';
import theme from './theme.json';
import 'echarts/lib/component/title';

ECharts.registerTheme('theme-color', theme);

export default {
  name: 'e-chart-line',
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      line: {
        title: {
          text: this.optionData.title,
          padding: 10,
          left: 'center',
        },
        // Make gradient line here
        visualMap: [
          {
            show: true,
            type: 'continuous',
            min: 0,
            max: 400,
          },
        ],
        grid: {
          width: '90%',
          left: '40px',
          top: '40px',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: [
          {
            boundaryGap: false,
            data: this.optionData.xAxisData,
          },
        ],
        yAxis: {
          type: 'value',
          splitLine: { show: true },
        },
        series: {
          type: 'line',
          showSymbol: false,
          data: this.optionData.series,
        },
      },
    };
  },
};
</script>

<style>
.echarts {
  
  width: 100% !important;
}
</style>
