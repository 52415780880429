import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import moduleAuth from './auth';
import moduleDocuments from './documents';
import moduleTrainings from './trainings';
import moduleOrderService from './orderService';
import moduleDashboard from './dashboard';
import moduleSendPdf from './sendPdf';
import moduleShared from './shared';
import moduleUser from './user';
import moduleClients from './clients'
import modulePdfGroup from './pdfGroup'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    moduleAuth,
    moduleDocuments,
    moduleTrainings,
    moduleOrderService,
    moduleDashboard,
    moduleSendPdf,
    moduleShared,
    moduleUser,
    moduleClients,
    modulePdfGroup,
  },
  strict: process.env.DEV,
});
