import httpQuality from '../apiQuality';
import http from '../api';

let getCompanyClients = (data) => {
  console.log(data)
  return httpQuality.get(`clients/?perPage=3000&company_id=${data.company_id}&search=${data.search}`);
};

let getClientEquipments = (data) => {
  return httpQuality.get(`equipments/?perPage=3000&company_id=${data.company_id}&client_id=${data.client_id}`)
}

let getClientUser = (data) => {
  return http.get(`get-client-user/?company_id=${data.company_id}&user_id=${data.user_id}`);
};
let getAllClientSearch = (data) => {
  return httpQuality.get(`client-search/${data}`);

};
export default {
  getCompanyClients,
  getClientEquipments,
  getClientUser,
  getAllClientSearch,
};