import { dashboardDao } from '@/service';

export default {
  state: {
    dashboard: [],
    currentGraphic: [],
  },
  mutations: {
    SET_DOCUMENTS_ALL(state, payload) {
      state.dashboard = payload;
    },
    SET_CURRENT_GRAPHIC(state, payload) {
      state.currentGraphic = payload;
    },
  },
  actions: {
    getAllDashboard({ commit, getters }) {
      const config = {
        params: {
          company_id: getters.company.id,
        },
      };
      return new Promise((resolve, reject) => {
        dashboardDao
          .getAllDashboard(config)
          .getPage()
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getDashboard({ commit, getters }) {
      const config = {
        params: {
          company_id: getters.company.id,
        },
      };
      return new Promise((resolve, reject) => {
        dashboardDao
          .getDashboard(config)
          .getPage()
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getDashboardFilter({ commit, getters }, payload) {
      const config = {
        params: {
          company_id: getters.company.id,
          client_id: payload.client_id
        },
      };
      return new Promise((resolve, reject) => {
        dashboardDao
          .getDashboardFilter(config)
          .getPage()
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    
    saveDashboard({ commit }, payload) {
      return new Promise((resolve, reject) => {
        dashboardDao
          .saveDashboard(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
  },

  getters: {
    dashboardAll: (state) => state.dashboard,
    currentGraphic: (state) => state.currentGraphic,
  },
};
