import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';
//mix
import './mixin/mixinShared.js';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';



import momentTimeZone from 'moment-timezone';
Vue.prototype.momentTimeZone = momentTimeZone;


import moment from 'moment'
Vue.prototype.moment = moment

import vSelect from 'vue-select';
import '@/@core/scss/vue/libs/vue-select.scss';
Vue.component('v-select', vSelect);


//validate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

store.dispatch('initAuth').then(() =>
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
);
