import { documentsDao } from '@/service';

export default {
  state: {
    documents: [],
  },
  mutations: {
    SET_DOCUMENTS_ALL(state, payload) {
      state.documents = payload;
    },
  },
  actions: {
    getAll({ commit, getters },payload) {
      const config = {
        params: {
          perPage:  50,
          page: payload&& payload.page?payload.page: 1,
          company_id: getters.company.id,
        },
      };
      return new Promise((resolve, reject) => {
        documentsDao
          .getAll(config)
          .getPage()
          .then((resp) => {
            commit('SET_DOCUMENTS_ALL', resp.data.data);
            resolve(resp.data);
          })
          .catch((err) => {
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar documentos!',
              text: err.response.data.data,
              color: 'danger',
            });
            reject(err);
          });
      });
    },
    getAllDocuments({ commit }, payload) {
      const config = {
        params: {
          perPage:  1000,
          company_id: payload.company_id,
          client_id: payload.client_id
        },
      };
      return new Promise((resolve, reject) => {
        documentsDao
          .getAllDocuments(config)
          .getPage()
          .then((resp) => {
            commit('SET_DOCUMENTS_ALL', resp.data.data);
            resolve(resp.data.data);
          })
          .catch((err) => {
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar documentos!',
              text: err.response.data,
              color: 'danger',
            });
            reject(err);
          });
      });
    },
    getDocument({ commit }, payload) {
      return new Promise((resolve, reject) => {
        documentsDao
          .getDocument(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar documento!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },
  },
  getters: {
    documentsAll: (state) => state.documentsAll,
  },
};
