import http from '../api';

let getPdfGroups = (data) => {
  return http.get(`pdf-group/?company_id=${data.company_id}`);
};

let savePdfGroup = (data) => {
  return http.post(`pdf-group`, data);
};

let updatePdfGroup = (data) => {
  return http.put(`pdf-group/${data.pdf_group_id}`, data);
};

let removePdfGroup = (data) => {
  return http.delete(`pdf-group/${data}`);
};

let changeExternalPdfGroup = (data) => {
  return http.post(`pdf-group-change-external`, data)
};


export default {
  getPdfGroups,
  savePdfGroup,
  updatePdfGroup,
  removePdfGroup,
  changeExternalPdfGroup,
};
