import Paginator from '../_paginator'
import http from '../api'

let getAll = (config) => {
  return new Paginator({
    url: '/client-trainings/',
    config: config
  });
}
let getAllTrainings = (config) => {
  return new Paginator({
    url: '/client-trainings/',
    config: config
  });
}

let getTraining = (id) => {
  return http.get(`client-trainings/${id}`)
}


export default {
  getAll,
  getAllTrainings,
  getTraining,
}