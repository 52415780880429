import http from '../api';

let insertPdf = (data) => {
  return http.post(`pdfs`, data);
};

let getPdf = (pdf_id) => {
  return http.get(`pdfs/${pdf_id}`);
};

let getAllExternalPdfs = (data) => {
  return http.get(`external-pdfs/?company_id=${data.company_id}&client_id=${data.client_id}&perPage=${data.perPage}&page=${data.page}&search=${data.search}&pdf_group_id=${data.pdf_group_id}`);
};

let insertExternalPDF = (data) => {
  return http.post(`external-pdfs`, data)
}

let removeExternalPDF = (data) => {
  return http.delete(`external-pdfs/${data}`);
};

export default {
  insertPdf,
  getPdf,
  getAllExternalPdfs,
  insertExternalPDF,
  removeExternalPDF,
};
