import { userDao } from '@/service';

export default {
  state: {
    token: '',
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    emailRecoveryPassword(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .emailRecoveryPassword(payload)
          .then((resp) => {
            //commit('SET_TOKEN', resp.data.token.token);
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },

    verifyHashRecoveryPassword(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .verifyHashRecoveryPassword(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },

    changePassword({ state }, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .changePassword(payload, state.token)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
  },
};
