import Paginator from '../_paginator'
import http from '../api'

let getAll = (config) => {
  return new Paginator({
    url: '/pdfs/',
    config: config
  });
}
let getAllDocuments = (config) => {
  return new Paginator({
    url: '/pdfs/',
    config: config
  });
}

let getDocument = (id) => {
  return http.get(`pdfs/${id}`)
}


export default {
  getAll,
  getAllDocuments,
  getDocument,
}