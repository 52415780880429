import { trainingsDao } from '@/service';

export default {
  state: {
    trainings: [],
  },
  mutations: {
    SET_TRAININGS_ALL(state, payload) {
      state.trainings = payload;
    },
  },
  actions: {
    getAllClientTrainings({ commit, getters }) {
      const config = {
        params: {
          perPage:  1000,
          company_id: getters.company.id,
        },
      };
      return new Promise((resolve, reject) => {
        trainingsDao
          .getAll(config)
          .getPage()
          .then((resp) => {
            commit('SET_TRAININGS_ALL', resp.data.data);
            resolve(resp.data.data);
          })
          .catch((err) => {
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar trainingos!',
              text: err.response.data.data,
              color: 'danger',
            });
            reject(err);
          });
      });
    },
    getAllTrainings({ commit }, payload) {
      const config = {
        params: {
          perPage:  1000,
          company_id: payload.company_id,
          client_id: payload.client_id
        },
      };
      return new Promise((resolve, reject) => {
        trainingsDao
          .getAllTrainings(config)
          .getPage()
          .then((resp) => {
            commit('SET_TRAININGS_ALL', resp.data.data);
            resolve(resp.data.data);
          })
          .catch((err) => {
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar trainingos!',
              text: err.response.data,
              color: 'danger',
            });
            reject(err);
          });
      });
    },
    getTraining({ commit }, payload) {
      return new Promise((resolve, reject) => {
        trainingsDao
          .getTraining(payload)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
            commit('SET_MESSAGE_NOTIFICATION', {
              title: 'Erro ao buscar trainingo!',
              text: err.response.data,
              color: 'danger',
            });
          });
      });
    },
  },
  getters: {
    trainingsAll: (state) => state.trainingsAll,
  },
};
