import Paginator from '../_paginator';
import http from '../api'


let getAllDashboard = (config) => {
  return new Paginator({
    url: '/dashboard-graphic/',
    config: config,
  });
};
let getDashboard = (config) => {
  return new Paginator({
    url: '/current-graphic/',
    config: config
  });
}
let getDashboardFilter = (config) => {
  return new Paginator({
    url: '/dashboard-graphic/',
    config: config
  });
}
let saveDashboard = (data) => {
  return http.post('current-graphic', data)
}


export default {
  getAllDashboard,
  saveDashboard,
  getDashboardFilter,
  getDashboard,
};
